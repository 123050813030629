<template>
  <div>
    <form>
      <h1>Transaktion</h1>
      <input list="ISIN" type="text" v-model="ISIN" placeholder="ISIN" />
      <datalist id="ISIN">
        <option v-for="position in ISINs" :key="position" :value="position.ISIN">{{position.ISIN + ' - ' + position.Kurzform}}</option>
      </datalist>
      <div>{{Name()}}</div>
      <input type="date" v-model="Datum" placeholder="Datum" />
      <input type="time" v-model="Zeit" placeholder="Zeit" step="1" />
      <input type="number" v-model="Menge" placeholder="Menge" />
      <input type="number" v-model="Kurs" placeholder="Kurs" v-on:keyup.enter="Speichern" />
      <input type="number" v-model="Kosten" placeholder="Kosten" v-on:keyup.enter="Speichern" />
      <div>{{Geldbetrag(Menge * Kurs + Kosten)}}</div>
      <button type="button" v-on:click="Speichern">Speichern</button>
      <button type="button" v-on:click="Zuruck" class="buttonBottom">Zurück</button>
      <button type="button" v-on:click="TransaktionLoschen" class="buttonBottom">🗑</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TransaktionView',

  components: {

  },

  data () {
    return {
      tId: this.$route.params.tId,
      ISIN: '',
      Datum: '',
      Menge: '',
      Kurs: '',
      Kosten: 0,
      Kaufen: true,
      user: 'Sebastian',
      ISINs: []
    }
  },

  methods: {
    ISINsLaden: function () {
      axios.post('https://sebastian-mitte.de/Depot/getData.php', {
        Funktion: 'ISINsLaden',
        Name: this.user,
        Passwort: '5539ec14d3db6b2fe2738a5f548278b130b4dbbd1b75f0b41fb1874e3970ba95'
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.ISINs = response.data
      })
    },

    TransaktionLaden: function () {
      axios.post('https://sebastian-mitte.de/Depot/getData.php', {
        Funktion: 'TransaktionLaden',
        Name: this.user,
        Passwort: '5539ec14d3db6b2fe2738a5f548278b130b4dbbd1b75f0b41fb1874e3970ba95',
        transaktionId: this.tId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.ISIN = response.data.ISIN
        this.Datum = response.data.Zeit.substring(0, 10)
        this.Zeit = response.data.Zeit.substring(11, 19)
        this.Menge = parseFloat(response.data.Menge)
        this.Kurs = parseFloat(response.data.Kurs)
        this.Kosten = parseFloat(response.data.Kosten)
        this.ISINsLaden()
      })
    },

    TransaktionLoschen: function () {
      if (!confirm('Transaktion wirklich löschen?')) return null
      axios.post('https://sebastian-mitte.de/Depot/getData.php', {
        Funktion: 'TransaktionLöschen',
        Name: this.user,
        Passwort: '5539ec14d3db6b2fe2738a5f548278b130b4dbbd1b75f0b41fb1874e3970ba95',
        transaktionId: this.tId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) {
          console.log(response.data)
        } else {
          this.Zuruck()
        }
      })
    },

    Speichern: function () {
      if (this.ISIN.length === 12 && this.Datum !== '' && this.Zeit !== '' && this.Menge !== '' && this.Kurs !== '') {
        if (this.Kosten === '') this.Kosten = 0
        axios.post('https://sebastian-mitte.de/Depot/getData.php', {
          Funktion: 'TransaktionSpeichern',
          Name: this.user,
          Passwort: '5539ec14d3db6b2fe2738a5f548278b130b4dbbd1b75f0b41fb1874e3970ba95',
          transaktionId: this.tId,
          ISIN: this.ISIN,
          Zeit: this.Datum + ' ' + this.Zeit,
          Menge: this.Menge,
          Kurs: this.Kurs,
          Kosten: this.Kosten
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          if (response.data) {
            console.log(response.data)
          } else {
            this.Zuruck()
          }
        })
      }
    },

    Zuruck: function () {
      this.$router.go(-1)
    },

    Name: function () {
      let Ergebnis = null
      this.ISINs.forEach(position => {
        if (position.ISIN === this.ISIN) Ergebnis = position.Name
      })
      if (Ergebnis) return Ergebnis
      return this.ISIN.length === 12 ? this.ISIN : ''
    },

    Geldbetrag: function (x) {
      if (x === '---' || x === null) return '---'
      return x.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })
    }
  },

  computed: {

  },

  mounted: function () {
    if (this.tId !== 'neu') {
      this.TransaktionLaden()
    } else {
      this.ISINsLaden()
    }
  }
}
</script>

<style scoped>
  .buttonBottom {
    font-size: 1em;
    width: 50%;
    margin-top: 0;
  }

  button {
    cursor: pointer;
  }
</style>
