<template>
  <div class="Dividende" :class="{later: !active}">
    <div style="width: 52%; padding-left: 1%; float: left;">
      <div id="Datum">
        {{ExTag.getDate() + '.' + (ExTag.getMonth() + 1) + '.' + (ExTag.getYear() + 1900)}} -
        {{Zahltag.getDate() + '.' + (Zahltag.getMonth() + 1) + '.' + (Zahltag.getYear() + 1900)}}
      </div>
      <div id="Name">
        {{Name}}
      </div>
    </div>
    <div id="Details">
      <div style="float: left; width: 100%; overflow: hidden; white-space: nowrap;" :class="{blur: blur}">
        {{Menge.toFixed(3)}}<span style="opacity: 0;"> €</span>
      </div>
      <div style="float: left; width: 100%; overflow: hidden; white-space: nowrap;">
        x {{Dividende.toFixed(2)}} €
      </div>
    </div>
    <div id="Wert" :class="{blur: blur}">
      {{(Menge * Dividende).toFixed(2)}} €
    </div>
  </div>
</template>

<script>
export default {
  name: 'DividendeComponent',
  props: {
    Name: { type: String },
    Menge: { type: Number },
    Dividende: { type: Number },
    ExTag: { type: Date },
    Zahltag: { type: Date },
    blur: { type: Boolean }
  },

  data () {
    return {

    }
  },

  computed: {
    active: function () {
      let heute = new Date().getTime() - 8 * 60 * 60 * 1000 - 1000 * 60 * new Date().getTimezoneOffset()
      heute = heute - heute % (24 * 60 * 60 * 1000) - ((new Date(heute).getDay() === 6 ? 1 : 0) + (new Date(heute).getDay() === 0 ? 2 : 0)) * 24 * 60 * 60 * 1000
      return this.ExTag.getTime() < heute + 24 * 60 * 60 * 1000
    }
  }
}
</script>

<style scoped>

div {
  margin: 0;
  padding: 0;
}

.Dividende {
  float: left;
  width: calc(100% - 3px);
  border: 1.5px #7030A0 solid;
  margin-bottom: 5px;
  border-radius: 3px;
  position: relative;
  font-size: 0.9em;
  cursor: pointer;
}

#Datum {
  color: #AAAAAA;
  width: 100%;
  float: left;
  font-size: 0.8em;
}

#Name {
  width: 100%;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.2em;
}

#Details {
  width: 18%;
  float: left;
  text-align: right;
  opacity: 0.5;
  font-size: 1em;
}

#Wert {
  width: calc(29% - 1px);
  float: left;
  font-size: 1.5em;
  text-align: right;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 1px;
}

.later {
  opacity: 0.5;
}

</style>
