<template>
  <div>
    <form>
      <h1>Dividende</h1>
      <input list="ISIN" type="text" v-model="ISIN" placeholder="ISIN" />
      <datalist id="ISIN">
        <option v-for="position in ISINs" :key="position" :value="position.ISIN">{{position.ISIN + ' - ' + position.Kurzform}}</option>
      </datalist>
      <div>{{Name()}}</div>
      <div>Ex-Tag</div>
      <input type="date" v-model="ExTag" placeholder="Ex-Tag" />
      <div>Zahltag</div>
      <input type="date" v-model="Zahltag" placeholder="Zahltag" />
      <input type="number" v-model="Dividende" placeholder="Dividende je Aktie" step="0.01" min="0" v-on:keyup.enter="Speichern" />
      <button type="button" v-on:click="Speichern">Speichern</button>
      <button type="button" v-on:click="Zuruck" class="buttonBottom">Zurück</button>
      <button type="button" v-on:click="DividendeLoschen" class="buttonBottom">🗑</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DividendeView',

  components: {

  },

  data () {
    return {
      dId: this.$route.params.dId,
      ISIN: '',
      ExTag: '',
      Zahltag: '',
      Dividende: '',
      ISINs: []
    }
  },

  methods: {
    ISINsLaden: function () {
      axios.post('https://sebastian-mitte.de/Depot/getData.php', {
        Funktion: 'ISINsLaden',
        Name: 'Sebastian',
        Passwort: '5539ec14d3db6b2fe2738a5f548278b130b4dbbd1b75f0b41fb1874e3970ba95'
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.ISINs = response.data
      })
    },

    DividendeLaden: function () {
      axios.post('https://sebastian-mitte.de/Depot/getData.php', {
        Funktion: 'DividendeLaden',
        Name: 'Sebastian',
        Passwort: '5539ec14d3db6b2fe2738a5f548278b130b4dbbd1b75f0b41fb1874e3970ba95',
        dividendeId: this.dId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.ISIN = response.data.ISIN
        this.ExTag = response.data.ExTag
        this.Zahltag = response.data.Zahltag
        this.Dividende = parseFloat(response.data.Dividende)
        this.ISINsLaden()
      })
    },

    DividendeLoschen: function () {
      if (!confirm('Dividende wirklich löschen?')) return null
      axios.post('https://sebastian-mitte.de/Depot/getData.php', {
        Funktion: 'DividendeLöschen',
        Name: 'Sebastian',
        Passwort: '5539ec14d3db6b2fe2738a5f548278b130b4dbbd1b75f0b41fb1874e3970ba95',
        dividendeId: this.dId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) {
          console.log(response.data)
        } else {
          this.Zuruck()
        }
      })
    },

    Speichern: function () {
      if (this.ISIN.length === 12 && this.ExTag !== '' && this.Zahltag !== '' && this.Dividende !== '') {
        axios.post('https://sebastian-mitte.de/Depot/getData.php', {
          Funktion: 'DividendeSpeichern',
          Name: 'Sebastian',
          Passwort: '5539ec14d3db6b2fe2738a5f548278b130b4dbbd1b75f0b41fb1874e3970ba95',
          dividendeId: this.dId,
          ISIN: this.ISIN,
          ExTag: this.ExTag,
          Zahltag: this.Zahltag,
          Dividende: this.Dividende
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          if (response.data) {
            console.log(response.data)
          } else {
            this.Zuruck()
          }
        })
      }
    },

    Zuruck: function () {
      this.$router.go(-1)
    },

    Name: function () {
      let Ergebnis = null
      this.ISINs.forEach(position => {
        if (position.ISIN === this.ISIN) Ergebnis = position.Name
      })
      if (Ergebnis) return Ergebnis
      return this.ISIN.length === 12 ? this.ISIN : ''
    }
  },

  computed: {

  },

  mounted: function () {
    if (this.dId !== 'neu') {
      this.DividendeLaden()
    } else {
      this.ISINsLaden()
    }
  }
}
</script>

<style scoped>
  .buttonBottom {
    font-size: 1em;
    width: 50%;
    margin-top: 0;
  }

  button {
    cursor: pointer;
  }
</style>
