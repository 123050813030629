import { createRouter, createWebHistory } from 'vue-router'

import AnmeldungView from '@/views/AnmeldungView'
import LoginView from '@/views/LoginView'
import DashboardView from '@/views/DashboardView'
import TransaktionView from '@/views/TransaktionView'
import DividendeView from '@/views/DividendeView'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:catchAll(.*)',
      redirect: '/'
    },
    {
      path: '/Anmeldung',
      name: 'Anmeldung',
      component: AnmeldungView
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginView
    },
    {
      path: '/',
      name: 'Dashboard',
      component: DashboardView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/Transaktion/:tId',
      name: 'Transaktion',
      component: TransaktionView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/Dividende/:dId',
      name: 'Dividende',
      component: DividendeView,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const currentUser = localStorage.Passwort ? localStorage.Benutzer : false
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !currentUser) next('Login')
  else if (!requiresAuth && currentUser) next('Dashboard')
  else next()
})

export default router
