export default {
  methods: {
    Logout: function () {
      localStorage.removeItem('Passwort')
      this.$router.replace('Login')
    },

    dataURL: function () {
      return 'https://depot.sebastian-mitte.de/getData.php'
    },

    Benutzer: function () {
      return localStorage.Benutzer
    },

    Passwort: function () {
      return localStorage.Passwort
    }
  }
}
