<template>
  <div>
    <form>
      <h1>Anmeldung</h1>
      <input type="text" v-model="Name" placeholder="Name" />
      <input type="email" v-model="EMail" placeholder="E-Mail" />
      <input type="password" v-model="Passwort" placeholder="Passwort" v-on:keyup.enter="Anmelden" />
      <button type="button" v-on:click="Anmelden">Anmelden</button>
      <p>Du hast schon ein Konto? <router-link to="/Login">Dann logge dich ein!</router-link></p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'AnmeldungView',

  components: {

  },

  data () {
    return {
      Name: '',
      EMail: '',
      Passwort: ''
    }
  },

  methods: {
    Anmelden: function () {
      if (this.Name !== '' && this.EMail !== '' && this.Passwort !== '') {
        this.$router.push('Login')
      } else {
        alert('Es müsen alle Felder ausgefüllt werden.')
      }
    }
  }
}
</script>

<style scoped>

</style>
