<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/Montserrat.ttf') format('truetype');
}

body {
  margin: 0;
  background-color: #F2EAF9;
}

#app {
  font-family: 'Montserrat', 'Calibri', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7030A0;
}

a {
  color: #7030A0;
  text-decoration: underline;
}

div {
  user-select: none;
  margin: 0;
}

button {
  background-color: #7030A0;
  color: #FFFFFF;
  font-weight: bold;
  border-radius: 1em;
  border: 0;
}

form {
  text-align: center;
  overflow: auto;

  width: 80%;
  margin-left: 9%;
  max-height: 90vh;
  margin-top: 4vh;

  border-radius: 2em;
  border: 1vh solid #7030A0;
}

form input, form select {
  width: 80%;
  margin: 0.3em;
  padding: 0.5em;
  background-color: #FFFFFF;
  border: 1px solid #7030A0;
}

form button {
  font-size: 1.4em;

  width: 80%;
  padding: 0.1em;
  margin: 0.5em;
}

h1 {
  font-family: 'Calibri', Arial, sans-serif;
  margin-bottom: 1vh;
  width: 100%;
  margin-top: 0;
  text-decoration: underline;
  font-weight: bold;
  font-size: 3vh;
  color: #FFFFFF;
  text-shadow: -1px -1px #7030A0, -1px 1px #7030A0, 1px -1px #7030A0, 1px 1px #7030A0;
}

h2 {
  text-align: center;
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 0.5vh;
  color: #7030A0;
  text-decoration: underline;
  font-size: 2.5vh;
}

input, select {
  margin-bottom: 2vh;
  width: 98%;
  padding: 0.5%;
  font-size: 1.2em;
  background-color: #FFFFFF;
  border: 1px solid #7030A0;
}
</style>
