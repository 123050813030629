<template>
  <div>
    <form>
      <h1>Login</h1>
      <input type="text" v-model="Benutzer" placeholder="Benutzer" />
      <input type="password" v-model="Passwort" placeholder="Passwort" v-on:keyup.enter="Einloggen" />
      <button type="button" v-on:click="Einloggen">Einloggen</button>
      <p>Du hast noch kein Konto? <router-link to="/Anmeldung">Dann melde dich an!</router-link></p>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import sha256 from 'crypto-js/sha256'

export default {
  name: 'LoginView',

  components: {

  },

  data () {
    return {
      Benutzer: '',
      Passwort: ''
    }
  },

  methods: {
    Einloggen: function () {
      if (this.Benutzer.length >= 3 && this.Passwort.length >= 3) {
        const hash = sha256(this.Passwort)
        axios.post('https://depot.sebastian-mitte.de/getData.php', {
          Funktion: 'Login',
          Name: this.Benutzer,
          Passwort: hash
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          console.log(response.data)
          if (response.data === 'Falsche Zugangsdaten') {
            alert('Zugangsdaten falsch.')
          } else if (response.data === 'Wartung') {
            alert('Wartungspause')
          } else {
            localStorage.Benutzer = this.Benutzer
            localStorage.Passwort = hash
            this.$router.go('Dashboard')
          }
        })
      }
    }
  },

  mounted: function () {
    this.Benutzer = localStorage.Benutzer
  }
}
</script>

<style scoped>

</style>
